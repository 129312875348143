module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Soho Champagne Unit 1608","short_name":"Soho Champagne","start_url":"/","background_color":"#eb3925","theme_color":"#eb3925","display":"minimal-ui","icon":"src/images/house.png","icons":[{"src":"/favicons/house.png","sizes":"48x48","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a6fbc1c9b86ea0543f4af9dfbb9ae410"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-96337374-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
